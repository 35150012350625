<nb-layout windowMode>
    <nb-layout-header fixed>
     <app-header></app-header>
    </nb-layout-header>

    <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
      <ng-content select="nb-menu"></ng-content>
      <!-- <nb-sidebar-footer>
        <ng-content  select="[sideBarfooter]"></ng-content>
      </nb-sidebar-footer> -->
    </nb-sidebar>

    <nb-layout-column>
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>

    <!-- <nb-layout-footer fixed>
      <ngx-footer></ngx-footer>
    </nb-layout-footer> -->
  </nb-layout>