import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }
  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];
  userPictureOnly: boolean = false;
  user: any;

  ngOnInit(): void {
  }

  
}
