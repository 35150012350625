<div class="header-container">
    <div class="logo-container">
      <span class="logo">Ritswa CMS</span>
    </div>
  </div>
  
  <!-- <div class="header-container">
    <nb-actions size="small">
      <nb-action class="control-item" icon="email-outline"></nb-action>
      <nb-action class="control-item" icon="bell-outline"></nb-action>
      <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
        <nb-user [nbContextMenu]="userMenu"
                 [onlyPicture]="userPictureOnly"
                 [name]="user?.name"
                 [picture]="user?.picture">
        </nb-user>
      </nb-action>
    </nb-actions>
  </div> -->
  